import React, { useEffect, useRef, useMemo } from "react";
import { z } from "zod";

// utils
import { US_STATES } from "@/shared/utils/constants";
import { UIUtils } from "@/shared/utils/UIUtils";
import { PublicConfig } from "@/shared/PublicConfig";

// components
import Terms from "./Terms";
import StripeCheckout from "./StripeCheckout";
import PolicySummary from "@/shared/components/PolicySummary";
import { FormField } from "@/shared/components/FormField";
import { Label } from "@/shared/components/ui/label";
import { Select } from "@/shared/components/ui/Select";
import { Heading } from "@/shared/components/ui/Heading";
import { InputWithLabel } from "@/shared/components/ui/InputWithLabel";
import { RadioButtonGroup } from "@/shared/components/ui/RadioButtonGroup";

// hooks
import { useBreeds } from "@/shared/hooks/useBreeds";
import { useFormContext, Controller } from "react-hook-form";
import { useMaskito } from "@maskito/react";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import { useFormParentContext } from "@/shared/contexts/FormParent";

// media
import DollarIcon from "../../../../src/media/icons/dollars-icon.svg";
import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// types
import { ErrorIdType } from "@/shared/types/SpotAPI";
import { NonNullableKeys, ObjectPaths } from "@/shared/utils/TypeUtils";
import { FormStepProps, OtherProps } from "@/shared/types/Form";
import { BillingStepSchema, Quote } from "@/shared/types/Quote.interface";
import { AnimatePresence, motion } from "framer-motion";

export type AllBillingStepProps = z.infer<typeof BillingStepSchema>;
type StepKeys = Extract<keyof Quote, keyof AllBillingStepProps>;
type OtherKeys = Exclude<keyof AllBillingStepProps, keyof Quote>;

type AllBillingStepPaths = ObjectPaths<NonNullableKeys<AllBillingStepProps>>;

export function BillingEditor(props: FormStepProps<Quote, StepKeys, OtherProps | undefined>) {
    const { appState, updateAppState } = useAppLayerContext();
    const { asyncErrors, showCreditCardFields } = appState;
    const quote = props?.value as Quote;
    const isStripeCheckoutEnabled = PublicConfig.PTZ_US.THIRD_PARTY_PAYMENT_PROCESSOR.toUpperCase() === "STRIPE";

    const {
        control,
        setValue,
        setError,
        setFocus,
        clearErrors,
        watch,
        getValues,
        formState: { errors, submitCount, touchedFields, dirtyFields, isDirty }
    } = useFormContext<AllBillingStepProps>();

    const { getValues: getFormParentValues } = useFormParentContext();

    const billingZipcode = watch("billingInfo.address.zipCode");

    // Billing Frequency
    const calculatedFrequency = appState?.billingFrequency;
    const currentFrequency = quote?.billingInfo?.frequency ?? "monthly";
    const frequencyToDisplay = calculatedFrequency ?? currentFrequency;

    const phoneMask = useMaskito(UIUtils.PhoneMask);
    const zipMask = useMaskito(UIUtils.ZipCodeMask);
    const { updateQuote, isQuoteUpdating } = useAppLayerContext().appState;
    const ratingZipcode = props.value?.ratingZipcode;
    const initialZipcodeRef = useRef(false);
    const { breeds } = useBreeds(PublicConfig.PTZ_US.UNDERWRITER);

    const firstName = props.value?.firstName;
    const lastName = props.value?.lastName;
    const isPetParent = firstName?.toLowerCase() === "pet" && lastName?.toLowerCase() === "parent";
    const needsFirstLastName = !firstName || !lastName;

    const handleFrequencyChange = async (newValue: "monthly" | "yearly") => {
        if (isQuoteUpdating) return;
        const currentValues = getFormParentValues();
        // We do an optimistic update here on appState.billingFrequency to update UI price values before the network response comes back
        updateAppState({ billingFrequency: newValue });
        const previousValue = currentValues?.billingInfo?.frequency;
        try {
            const updatedQuote = await updateQuote?.mutateAsync({
                ...currentValues,
                billingInfo: { frequency: newValue }
            });

            const frequencyInResponse = updatedQuote?.billingInfo?.frequency ?? "monthly";
            setValue("billingInfo.frequency", frequencyInResponse, { shouldTouch: true, shouldDirty: true });
            updateAppState({
                billingFrequency: frequencyInResponse,
                isAnnualBilling: frequencyInResponse === "yearly"
            });
        } catch (error) {
            console.error("Error updating quote frequency:", error);

            // Revert to previous value if there was an error
            updateAppState({
                billingFrequency: previousValue
            });
        }
    };

    const handleExtraChange = async (updateValue: any) => {
        const currentValues = getFormParentValues();
        if (isQuoteUpdating) return;
        try {
            const currentExtra = currentValues?.extra;
            const mergedExtra = { ...currentExtra, ...updateValue };
            updateQuote?.mutate({ ...currentValues, extra: mergedExtra });
        } catch (error) {
            console.log(error);
        }
    };

    const allBillingErrorsMap: Partial<Record<ErrorIdType, { path?: AllBillingStepPaths; message: string; stripe?: boolean; header?: string }>> = useMemo(
        () => ({
            "invalid-billing-address": { path: "billingInfo.address.street1", message: "Invalid billing address" },
            "invalid-billing-name": { path: "billingInfo.nameOnCard", message: "Invalid billing name" },
            "invalid-credit-card-name": { path: "billingInfo.nameOnCard", message: "Invalid billing name" },
            "invalid-phone-number": { path: "phone", message: "Invalid phone number" },
            "invalid-postal-code": { path: "billingInfo.address.zipCode", message: "Invalid postal code" },
            "invalid-credit-card-cvv": { stripe: true, message: "", header: "Invalid Security Code" },
            "invalid-credit-card-declined": { stripe: true, message: "" },
            "invalid-credit-card-number": { stripe: true, message: "" }
        }),
        []
    );

    useEffect(() => {
        if (!!asyncErrors && asyncErrors?.length > 0 && submitCount > 0) {
            clearErrors();

            let handledAllErrors = true;
            let asyncStripeError: any | undefined = undefined;

            asyncErrors.forEach(error => {
                const errorMapping = allBillingErrorsMap[error.id];
                if (!!errorMapping && !!errorMapping.path) {
                    setError(errorMapping.path, { message: errorMapping.message });
                    setFocus(errorMapping.path);
                } else if (!!errorMapping && errorMapping?.stripe) {
                    asyncStripeError = errorMapping;
                } else {
                    handledAllErrors = false;
                }
            });

            // Handle Stripe specific PaymentElement errors
            if (!!asyncStripeError?.stripe) {
                updateAppState({ stripePaymentElementError: asyncStripeError });
            }

            // If we encounter an error that can't be handled, we set a flag to show a generic error message
            if (!handledAllErrors) {
                updateAppState({ hasUnknownError: true });
            }
        }
    }, [allBillingErrorsMap, asyncErrors, clearErrors, setError, setFocus, submitCount, updateAppState]);

    useEffect(() => {
        if (!billingZipcode && !!ratingZipcode && !initialZipcodeRef.current) {
            setValue(`billingInfo.address.zipCode`, ratingZipcode, { shouldTouch: true, shouldDirty: true });
            initialZipcodeRef.current = true;
        }
    }, [billingZipcode, ratingZipcode, setValue]);

    // Clear any payment element errors when the user switches between payment methods
    useEffect(() => {
        if (!showCreditCardFields) {
            updateAppState({ stripePaymentElementError: undefined });
        }
    }, [showCreditCardFields, updateAppState]);

    return (
        <>
            <div className="flex flex-col gap-7">
                <PolicySummary quote={quote} breeds={breeds} />
                {(isPetParent || needsFirstLastName) && (
                    <div className="mx-auto flex w-full max-w-[580px] flex-col gap-3">
                        <Heading level="h2" className="text-lg font-bold">
                            Pet Parent Info
                        </Heading>
                        <div className="flex flex-col gap-6">
                            <Controller
                                name="billingInfo.firstName"
                                control={control}
                                render={({ field: { ref, onChange, ...rest } }) => (
                                    <FormField className="min-w-[250px] flex-1" error={errors?.billingInfo?.firstName?.message} errorId="error-given-name">
                                        <InputWithLabel
                                            variant="floating"
                                            label="First Name"
                                            inputRef={ref}
                                            error={errors?.billingInfo?.firstName?.message}
                                            inputProps={{
                                                ...rest,
                                                autoComplete: "given-name",
                                                onChange: e => {
                                                    if (errors?.billingInfo?.firstName) {
                                                        clearErrors("billingInfo.firstName");
                                                    }
                                                    onChange(e);
                                                    const lastName = getValues(`billingInfo.lastName`);
                                                    const newNameOnCard = `${e.target.value} ${lastName ?? ""}`;
                                                    setValue("billingInfo.nameOnCard", newNameOnCard.trim(), { shouldTouch: true, shouldDirty: true });
                                                    if (errors?.billingInfo?.nameOnCard) {
                                                        clearErrors("billingInfo.nameOnCard");
                                                    }
                                                }
                                            }}
                                        />
                                    </FormField>
                                )}
                            />
                            <Controller
                                name="billingInfo.lastName"
                                control={control}
                                render={({ field: { ref, onChange, ...rest } }) => (
                                    <FormField className="min-w-[250px] flex-1" error={errors?.billingInfo?.lastName?.message} errorId="error-family-name">
                                        <InputWithLabel
                                            variant="floating"
                                            label="Last Name"
                                            inputRef={ref}
                                            error={errors?.billingInfo?.lastName?.message}
                                            inputProps={{
                                                ...rest,
                                                autoComplete: "family-name",
                                                onChange: e => {
                                                    if (errors?.billingInfo?.lastName) {
                                                        clearErrors("billingInfo.lastName");
                                                    }
                                                    onChange(e);
                                                    const firstName = getValues(`billingInfo.firstName`);
                                                    const newNameOnCard = `${firstName ?? ""} ${e.target.value}`;
                                                    setValue("billingInfo.nameOnCard", newNameOnCard.trim(), { shouldTouch: true, shouldDirty: true });
                                                    if (errors?.billingInfo?.nameOnCard) {
                                                        clearErrors("billingInfo.nameOnCard");
                                                    }
                                                }
                                            }}
                                        />
                                    </FormField>
                                )}
                            />
                        </div>
                    </div>
                )}
                <div className="mx-auto flex w-full max-w-[580px] grow flex-col">
                    <div className="flex flex-col gap-3">
                        <Heading level="h2" className="text-lg font-bold">
                            Payment Info
                        </Heading>
                        <div className="flex grow flex-col">
                            <div data-testid="billing-frequency-section" className="rounded-xl bg-background-success px-3 pb-1 pt-2">
                                <RadioButtonGroup
                                    onValueChange={async value => await handleFrequencyChange(value as "monthly" | "yearly")}
                                    initialValue={frequencyToDisplay}
                                    aria-labelledby="billing-frequency"
                                    className="bg-background-weakest"
                                    disabled={isQuoteUpdating}
                                    options={[
                                        { label: "Billed Monthly", value: "monthly" },
                                        { label: "Billed Annually", value: "yearly" }
                                    ]}
                                    data-testid="billing-frequency-group"
                                />
                                <div className="flex flex-row items-center justify-center gap-1 pt-2">
                                    {frequencyToDisplay === "yearly" ? <FontAwesomeIcon icon={faCircleCheck} className="size-5 text-content-primary" /> : <DollarIcon />}
                                    <span className="text-xs font-semibold text-content-primary">
                                        {frequencyToDisplay === "yearly" ? "You're saving $24/yr!" : "Save $24/yr with annual billing."}
                                    </span>
                                </div>
                            </div>
                            {isStripeCheckoutEnabled && <StripeCheckout />}
                        </div>
                    </div>
                    <AnimatePresence mode="sync">
                        {showCreditCardFields && (
                            <motion.div key="outer" initial={{ height: 0 }} animate={{ height: `auto` }} exit={{ height: 0 }}>
                                <motion.div key="inner" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.25 } }} exit={{ opacity: 0 }}>
                                    <div className="flex flex-col">
                                        <Heading level="h2" className="mb-3 text-lg font-bold">
                                            Billing Address
                                        </Heading>
                                        <Controller
                                            name="billingInfo.nameOnCard"
                                            control={control}
                                            render={({ field: { ref, ...rest } }) => (
                                                <FormField className="mb-5 min-w-[250px]" error={errors?.billingInfo?.nameOnCard?.message} errorId="error-creditCard-nameOnCard">
                                                    <InputWithLabel
                                                        variant="floating"
                                                        label="Full name"
                                                        inputRef={ref}
                                                        error={errors?.billingInfo?.nameOnCard?.message}
                                                        inputProps={{
                                                            ...rest,
                                                            autoComplete: "cc-name",
                                                            "aria-describedby": errors?.billingInfo?.nameOnCard ? "error-creditCard-nameOnCard" : undefined
                                                        }}
                                                    />
                                                </FormField>
                                            )}
                                        />
                                        <div className="flex flex-col gap-5">
                                            <Controller
                                                name="billingInfo.address.street1"
                                                control={control}
                                                render={({ field: { ref, onChange, ...rest } }) => (
                                                    <FormField
                                                        className="min-w-[250px] flex-1"
                                                        error={errors?.billingInfo?.address?.street1?.message}
                                                        errorId="error-address-street1"
                                                    >
                                                        <InputWithLabel
                                                            variant="floating"
                                                            label="Address 1"
                                                            inputRef={ref}
                                                            error={errors?.billingInfo?.address?.street1?.message}
                                                            inputProps={{
                                                                ...rest,
                                                                onChange: event => {
                                                                    onChange(event);
                                                                },
                                                                autoComplete: "address-line1",
                                                                "aria-describedby": errors?.billingInfo?.address?.street1 ? "error-address-street1" : undefined
                                                            }}
                                                        />
                                                    </FormField>
                                                )}
                                            />

                                            <Controller
                                                name="billingInfo.address.street2"
                                                control={control}
                                                render={({ field: { ref, onChange, ...rest } }) => (
                                                    <FormField
                                                        className="min-w-[250px] flex-1"
                                                        error={errors?.billingInfo?.address?.street2?.message}
                                                        errorId="error-address-street2"
                                                    >
                                                        <InputWithLabel
                                                            variant="floating"
                                                            label="Address 2"
                                                            inputRef={ref}
                                                            error={errors?.billingInfo?.address?.street2?.message}
                                                            inputProps={{
                                                                ...rest,
                                                                onChange: event => {
                                                                    onChange(event);
                                                                },
                                                                autoComplete: "address-line2",
                                                                "aria-describedby": errors?.billingInfo?.address?.street2 ? "error-address-street2" : undefined
                                                            }}
                                                        />
                                                    </FormField>
                                                )}
                                            />
                                            <Controller
                                                name="billingInfo.address.city"
                                                control={control}
                                                render={({ field: { ref, onChange, ...rest } }) => (
                                                    <FormField className="min-w-[250px] flex-1" error={errors?.billingInfo?.address?.city?.message} errorId="error-address-city">
                                                        <InputWithLabel
                                                            variant="floating"
                                                            label="City"
                                                            inputRef={ref}
                                                            error={errors?.billingInfo?.address?.city?.message}
                                                            inputProps={{
                                                                ...rest,
                                                                onChange: event => {
                                                                    onChange(event);
                                                                },
                                                                autoComplete: "address-level2",
                                                                "aria-describedby": errors?.billingInfo?.address?.city ? "error-address-city" : undefined
                                                            }}
                                                        />
                                                    </FormField>
                                                )}
                                            />
                                            <div className="flex gap-5 lg:col-span-2 xl:col-span-1">
                                                <FormField className="flex-1" error={errors?.billingInfo?.address?.state?.message} errorId="error-address-state">
                                                    <Label id="billing-address-state-label" variant="floating" size="xs" htmlFor="billing-address-state">
                                                        State
                                                    </Label>
                                                    <Controller
                                                        name="billingInfo.address.state"
                                                        control={control}
                                                        render={({ field: { onChange, value, ref } }) => (
                                                            <Select
                                                                value={value || ""}
                                                                options={US_STATES}
                                                                onValueChange={val => {
                                                                    onChange(val);
                                                                    setValue("billingInfo.address.zipCode", "", { shouldDirty: true });
                                                                }}
                                                                placeholder="Select a state"
                                                                ref={ref}
                                                                error={errors?.billingInfo?.address?.state?.message}
                                                                triggerProps={{
                                                                    id: "billing-address-state",
                                                                    "aria-labelledby": "billing-address-state-label",
                                                                    "aria-describedby": errors?.billingInfo?.address?.state ? "error-address-state" : undefined
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </FormField>

                                                <Controller
                                                    name="billingInfo.address.zipCode"
                                                    control={control}
                                                    render={({ field: { ref, ...rest } }) => (
                                                        <FormField className="flex-1" error={errors?.billingInfo?.address?.zipCode?.message} errorId="error-address-zipCode">
                                                            <InputWithLabel
                                                                variant="floating"
                                                                label="Zip code"
                                                                inputRef={el => UIUtils.chainRefs(el, zipMask, ref)}
                                                                error={errors?.billingInfo?.address?.zipCode?.message}
                                                                inputProps={{
                                                                    ...rest,
                                                                    placeholder: "00000",
                                                                    autoComplete: "postal-code",
                                                                    onInput: event => {
                                                                        rest.onChange(event.currentTarget.value);
                                                                        setValue(`billingInfo.address.zipCode`, event.currentTarget.value, {
                                                                            shouldTouch: true,
                                                                            shouldDirty: true
                                                                        });
                                                                    },
                                                                    "aria-describedby": errors?.billingInfo?.address?.zipCode ? "error-address-zipCode" : undefined
                                                                }}
                                                            />
                                                        </FormField>
                                                    )}
                                                />
                                            </div>
                                            <Controller
                                                name="phone"
                                                control={control}
                                                render={({ field: { ref, ...rest } }) => (
                                                    <FormField error={errors?.phone?.message} errorId="error-phone">
                                                        <InputWithLabel
                                                            variant="floating"
                                                            label="Mobile number"
                                                            inputRef={el => UIUtils.chainRefs(el, phoneMask, ref)}
                                                            error={errors?.phone?.message}
                                                            inputProps={{
                                                                ...rest,
                                                                autoComplete: "tel-national",
                                                                placeholder: "(555) 555-5555",
                                                                type: "tel",
                                                                onInput: event => {
                                                                    rest.onChange(event.currentTarget.value);
                                                                    setValue(`phone`, event.currentTarget.value, { shouldTouch: true, shouldDirty: true });
                                                                },
                                                                "aria-describedby": errors?.phone ? "error-phone" : undefined
                                                            }}
                                                        />
                                                    </FormField>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </motion.div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
                <Terms wrapperClass="max-w-[580px]" handleExtraChange={handleExtraChange} quoteExtra={quote.extra} isQuoteUpdating={isQuoteUpdating} />
            </div>
        </>
    );
}
